import React from 'react';

import { Layout, SEO } from 'src/components/shared/';
import { Hero } from 'src/components/home';

export default function IndexPage() {
	return (
		<Layout variant="full">
			<SEO title="Welcome" />
			<Hero></Hero>
		</Layout>
	);
}
