import React, { useRef, useEffect } from 'react';
import BackgroundImage from 'gatsby-background-image';
import Image from 'gatsby-image';
import Parallax from 'parallax-js';

import { motion } from 'framer-motion';

import Cars from '../Cars/Cars';
import ShootingStars from './ShootingStars';
import StarCanvas from './StarsCanvas';
import useImages from './useImages';

import style from './hero.module.scss';

export default function Hero() {
	const images = useImages();

	const sceneRef = useRef();

	useEffect(() => {
		new Parallax(sceneRef.current, {
			relativeInput: true,

			selector: '[data-layer]',
			pointerEvents: true,
			limitX: 0,
			scalarY: 2
		});
	}, []);

	return (
		<section className={style.section} ref={sceneRef}>
			<BackgroundImage
				fluid={images.sky}
				className={style.sky}
			></BackgroundImage>
			<BackgroundImage
				fluid={images.clouds}
				className={style.clouds}
				data-layer
				data-depth="0.1"
			></BackgroundImage>
			<BackgroundImage
				fluid={images.mountains}
				className={style.mountains}
				data-layer
				data-depth="0.3"
			></BackgroundImage>

			<StarCanvas></StarCanvas>
			<ShootingStars></ShootingStars>

			<div className={style.column}>
				<motion.div
					className={style.logoWrapper}
					initial="initial"
					animate="appear"
					variants={{
						initial: {
							opacity: 0
						},
						appear: { opacity: 1 }
					}}
					transition={{
						type: 'spring',
						stiffness: 70,
						damping: 1,
						mass: 0.2,
						delay: 2
					}}
				>
					<div className={style.logoContain}>
						<Image
							fluid={images.logo}
							className={style.logo}
						></Image>
					</div>
				</motion.div>
				<div className={style.boardWrapper} data-layer data-depth="0.4">
					<Image fluid={images.board} className={style.board}></Image>

					<div className={style.video}>
						<iframe
							src="https://player.vimeo.com/video/345721787"
							frameBorder="0"
							allow="autoplay; fullscreen"
							allowFullScreen
							title="Intro Video"
						></iframe>
						<script
							type="text/javascript"
							defer="defer"
							src="https://extend.vimeocdn.com/ga/96162754.js"
						></script>
					</div>
				</div>
			</div>

			<div className={style.leftTrees} data-layer data-depth="0.7">
				<Image fluid={images.leftTrees}></Image>
			</div>
			<div className={style.rightTrees} data-layer data-depth="0.7">
				<Image fluid={images.rightTrees}></Image>
			</div>

			<Cars data-layer data-depth="0.5"></Cars>
		</section>
	);
}
