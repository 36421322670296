import { useStaticQuery, graphql } from 'gatsby';

export default function useImages() {
	const data = useStaticQuery(
		graphql`
			query {
				sky: file(relativePath: { eq: "bg_just_sky.jpg" }) {
					childImageSharp {
						fluid(quality: 90, maxWidth: 2560) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				clouds: file(relativePath: { eq: "bg_just_clouds.png" }) {
					childImageSharp {
						fluid(quality: 80, maxWidth: 2560) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				mountains: file(relativePath: { eq: "bg_mountains.png" }) {
					childImageSharp {
						fluid(
							quality: 80
							maxWidth: 2560
							traceSVG: { color: "#202b53" }
						) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
				board: file(relativePath: { eq: "board.png" }) {
					childImageSharp {
						fluid(
							quality: 80
							maxWidth: 1200
							traceSVG: { color: "#221c1c" }
						) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
				logo: file(relativePath: { eq: "logo.png" }) {
					childImageSharp {
						fluid(quality: 80, maxWidth: 600) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				leftTrees: file(relativePath: { eq: "left_trees.png" }) {
					childImageSharp {
						fluid(
							quality: 80
							maxWidth: 960
							traceSVG: { color: "#49343f" }
						) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
				rightTrees: file(relativePath: { eq: "right_trees.png" }) {
					childImageSharp {
						fluid(
							quality: 80
							maxWidth: 960
							traceSVG: { color: "#3f3547" }
						) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
			}
		`
	);

	return {
		sky: data.sky.childImageSharp.fluid,
		clouds: data.clouds.childImageSharp.fluid,
		mountains: data.mountains.childImageSharp.fluid,
		board: data.board.childImageSharp.fluid,
		logo: data.logo.childImageSharp.fluid,
		leftTrees: data.leftTrees.childImageSharp.fluid,
		rightTrees: data.rightTrees.childImageSharp.fluid
	};
}
