import React, { useRef } from 'react';
import { useDebounce, useMeasure } from 'react-use';

import style from './hero.module.scss';

class Star {
	constructor(x, y, color, context) {
		this.x = x;
		this.y = y;
		this.r = Math.random() * 1.7 + 0.5;
		this.context = context;

		this.color = color;
	}

	render() {
		this.context.beginPath();
		this.context.arc(this.x, this.y, this.r, 0, 2 * Math.PI, false);

		this.context.fillStyle = this.color;
		this.context.fill();
	}
}

function useStars(canvasRef, width, height) {
	function randomColor() {
		const arrColors = ['ffffff', '6887ff', '8faafe'];
		return '#' + arrColors[Math.floor(Math.random() * 3)];
	}

	function render(arr, count) {
		console.log('Generating', count, 'stars');

		for (let i = 0; i < arr.length; i++) {
			arr[i].render();
		}
	}

	const lastWidth = useRef(width);

	useDebounce(
		() => {
			if (lastWidth.current !== width) {
				const canvas = canvasRef.current;
				const context = canvas.getContext('2d');

				canvas.width = width;
				canvas.height = height;

				lastWidth.current = width;

				const allStars = [];
				const starCount = Math.round(width / 8 / 2); // Stars equal to 1/8th the screen width. Halved because we want 2 layers

				for (let i = 0; i < starCount; i++) {
					const randX = Math.floor(Math.random() * width + 1);
					const randY = Math.floor(Math.random() * height + 1);

					const star = new Star(randX, randY, randomColor(), context);
					allStars.push(star);
				}

				context.clearRect(0, 0, width, height);
				render(allStars, starCount);
			}
		},
		250,
		[canvasRef, width, height]
	);
}

export default function StarsCanvas() {
	const canvasRef1 = useRef();
	const canvasRef2 = useRef();
	const [containerRef, { width, height }] = useMeasure();

	const starHeight = height * 0.6;

	useStars(canvasRef1, width, starHeight);
	useStars(canvasRef2, width, starHeight);

	return (
		<div className={style.canvasContainer} ref={containerRef}>
			<canvas ref={canvasRef1} className={style.canvas}></canvas>
			<canvas ref={canvasRef2} className={style.canvas}></canvas>
		</div>
	);
}
