import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import style from './cars.module.scss';

function Cars(props) {
	const data = useStaticQuery(
		graphql`
			query {
				allFile(
					filter: { relativeDirectory: { eq: "cars" } }
					sort: { fields: name }
				) {
					edges {
						node {
							id
							name
							childImageSharp {
								fluid(
									quality: 80
									maxWidth: 220
									traceSVG: { color: "#001950" }
								) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		`
	);

	const allCars = data.allFile.edges;

	return (
		<div className={style.cars} {...props}>
			{allCars.map(({ node }) => {
				return (
					<Image
						key={node.id}
						fluid={node.childImageSharp.fluid}
						className={style.img}
					></Image>
				);
			})}
		</div>
	);
}

export default Cars;
