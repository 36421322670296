import React, { useState } from 'react';
import { useInterval, useWindowSize } from 'react-use';
import { motion, useAnimation } from 'framer-motion';

import trail from 'src/images/trail.png';
import style from './hero.module.scss';

export default function ShootingStars() {
	const containerRandom = () => ({
		left: 20 + 60 * Math.random() + '%',
		rotate: (() => {
			const rand = Math.random();
			if (rand > 0.5) {
				return `${20 + rand * 40}deg`;
			} else {
				return `${120 + rand * 40}deg`;
			}
		})(),
		opacity: 1
	});

	const { width: windowWidth } = useWindowSize(); // Get around gatsby build failing on window width
	const controls = useAnimation();
	const [angle, setAngle] = useState(containerRandom);

	useInterval(async () => {
		if (window.matchMedia('(min-width: 768px)').matches) {
			setAngle(containerRandom());
			await controls.set('initial');
			await controls.start('fly');
		}
	}, 8000);

	return (
		<motion.div
			className={style.starContain}
			animate={angle}
			transition={{ duration: 0 }}
		>
			<motion.img
				src={trail}
				alt=""
				className={style.shootingStar}
				variants={{
					initial: {
						x: 0,
						opacity: 1,
						transition: { duration: 0 }
					},
					fly: {
						x: windowWidth,
						opacity: 0,
						transition: {
							duration: 1.5,
							ease: 'linear'
						}
					}
				}}
				animate={controls}
			></motion.img>
		</motion.div>
	);
}
